<template>
	<BlockEcommerceProductList
		:block-id="blockId"
		:products="productList"
		:block-style="blockStyle"
		:text-color-vars="textColorVars"
		:is-product-list-shown="isProductListShown"
		:products-per-page="productsPerPage"
		:is-loading="isLoading"
		:column-count="columnCount"
		:translations="ecommerceTranslations"
		:is-eager="lcp.type === 'block-ecommerce-product-list' && lcp.id === blockId"
		:product-pages="productPages"
		:product-ids="productIds"
		@page-changed="handlePageChange($event)"
	/>
</template>

<script>
import {
	mapActions,
	mapState,
} from 'vuex';
import { defineComponent } from 'vue';

import BlockEcommerceProductList from '@zyro-inc/site-modules/components/blocks/ecommerce/BlockEcommerceProductList.vue';
import { useBlockEcommerceProductList } from '@zyro-inc/site-modules/components/blocks/ecommerce/useBlockEcommerceProductList';
import { SYSTEM_LOCALE } from '@zyro-inc/site-modules/constants';

export default defineComponent({
	components: {
		BlockEcommerceProductList,
	},

	props: {
		blockId: {
			type: String,
			required: true,
		},
		data: {
			type: Object,
			default: () => ({}),
		},
		lcp: {
			type: Object,
			default: () => ({}),
		},
		ecommerceTranslations: {
			type: Object,
			default: () => ({}),
		},
		currentLocale: {
			type: String,
			default: SYSTEM_LOCALE,
		},
	},

	setup(props) {
		const {
			productList,
			productPages,
			blockStyle,
			textColorVars,
			isLoading,
			columnCount,
			productsPerPage,
			productIds,
		} = useBlockEcommerceProductList(props);

		return {
			productList,
			productPages,
			blockStyle,
			textColorVars,
			isLoading,
			columnCount,
			productsPerPage,
			productIds,
		};
	},

	computed: {
		...mapState(['website']),
		isProductListShown() {
			if (this.website.meta.defaultLocale && this.currentLocale !== this.website.meta.defaultLocale) {
				return false;
			}

			return !!this.productList?.length;
		},
	},
	methods: {
		...mapActions('ecommerce', ['getProducts']),
		handlePageChange(page) {
			const ids = this.productIds.slice(0, this.productsPerPage * page);

			this.getProducts(ids);
		},
	},
});
</script>
